import { convertPrice } from '@/utils/common';

export const SERVICE_PACKAGE = [
  {
    id: 'VIEON',
    name: 'Vieon SUB',
  },
];

export const PAYMENT_STATUS = Object.freeze([
  { status: 1, status_msg: 'STATUS_SUCCESS', description: 'Quá trình thanh toán diễn ra thành công – kết quả đã được ghi nhận' },
  { status: 2, status_msg: 'STATUS_FAIL', description: 'Việc thanh toán thất bại, nguyên nhân KHÔNG thuộc về VieON. Ví dụ: số dư không đủ, thông tin thẻ không hợp lệ…' },
  { status: 0, status_msg: 'STATUS_PROCESSING', description: 'Thông tin thanh toán đã được gửi đến các đối tác thanh toán, hệ thống đang đợi khách hàng thanh toán.' },
  { status: 3, status_msg: 'STATUS_ERROR', description: 'Việc thanh toán thất bại, nguyên nhân thuộc về hệ thống VieON. Ví dụ: lỗi hệ thống.' },
  { status: 4, status_msg: 'STATUS_CANCEL', description: 'Khách hàng chủ động hủy khi đang giao dịch.' },
  { status: 5, status_msg: 'STATUS_REFUND', description: 'Yêu cầu hoàn tiền đã được duyệt. Không trực tiếp hoàn tiền trên giao dịch thành công.' },
  { status: 6, status_msg: 'STATUS_INVALID_AMOUNT', description: 'Gọi lại paygate thành công nhưng số tiền không hợp lệ.' },
  { status: 99, status_msg: 'STATUS_ADMIN_CANCEL', description: 'Quản trị viên hủy.' },
  { status: 100, status_msg: 'STATUS_TIMEOUT', description: 'Timeout.' }
]);


export const SERVICE_PACKAGE_FIELDS = [
  {
    key: 'billNumber', // billNumber
    label: 'Số hóa đơn',
    thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
  },
  {
    key: 'customerInfo', // customerInfo
    label: 'Thông tin khách hàng',
    thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
  },
  {
    key: 'totalAmount', // status
    label: 'Tổng tiền',
    thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
    tdClass: 'text-right',
    formatter: (value) => {
      return value ? convertPrice(value) : 0;
    },
  },
  {
    key: 'provider',
    label: 'Thông tin từ NCC',
    thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
  },
  {
    key: 'status',
    label: 'Trạng thái',
    thStyle: {
      fontWeight: 600,
      color: '#181c32',
      width: '10%',
      textAlign: 'center',
    },
  },
  {
    key: 'createdBy',
    label: 'Người phụ trách',
    thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
  },
  {
    key: 'actions',
    label: '',
    thStyle: {
      width: '10%',
    },
    tdClass: 'align-middle',
  },
];
