export class SearchVieonProductTransaction {
  constructor(data) {
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
    this.billNumber = data.billNumber;
    this.module = data.module ? data.module : 'active-subscriptions';
    this.page = !isNaN(Number(data.page)) ? Number(data.page) : 1;
    this.size = !isNaN(Number(data.size)) ? Number(data.size) : 10;
  }
}
