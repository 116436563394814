export class VieonProductTransaction {
  constructor(data) {
    this.count = data.count;
    this.id = data.id;
    this.bill = data.bill;
    this.customer = data.customer;
    this.store = data.store;
    this.status = data.status;
    this.employee = data.employee;
    this.provider = data.provider;
    this.providerInfo = data.providerInfo;
    this.createdBy = data.createdBy;
    this.createdAt = data.createdAt;
  }
}
