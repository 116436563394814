export const VIEON_REQUEST_SUBS_STATUS = Object.freeze({
  FAIL: 2,
  SUCCESSFUL: 1,
  PROCESSING: 0,
  ERROR: 3,
  CANCEL: 4,
  REFUND: 5,
  INVALID_AMOUNT: 6,
  ADMIN_CANCEL: 99,
  TIMEOUT: 100,
});
